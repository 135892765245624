import React from 'react';
import TextRotationNoneIcon from '@mui/icons-material/TextRotationNone';
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';
import KeyboardExternalInput from '../assets/icons/keyboard_external_input.svg';
import { SvgIcon } from '@mui/material';
import {
	AngleTool,
	ArrowAnnotateTool,
	BidirectionalTool,
	EllipticalROITool,
	LengthTool,
	PlanarFreehandROITool,
	ProbeTool,
	RectangleROITool,
} from '@cornerstonejs/tools';
import CobbAngleTool from '../cornerstoneTools/CobbAngleTool';
import CardiothoracicTool from '../cornerstoneTools/CardiothoracicTool';
import PlumbLineTool from '../cornerstoneTools/PlumbLineTool';
import StraightenIcon from '@mui/icons-material/Straighten';
import SpineLabelingTool from '../cornerstoneTools/SpineLabelingTool';

const SRobjectIcon = ({ ...props }) => (
	<SvgIcon inheritViewBox component={KeyboardExternalInput} testid="SRobjectIcon" {...props} />
);

export const ALL_MEASUREMENTS = { label: 'All', value: 'all' };
export const cad_findings = 'cad_findings';
export const sr_object = 'sr_object';
export const annotation = 'annotation';
export const measurements = 'measurements';

export const MEASUREMENT_TYPES = {
	measurements: {
		label: 'Measurements',
		icon: <StraightenIcon color="inherit" />,
		value: measurements,
	},
	annotation: {
		label: 'Annotations',
		icon: <TextRotationNoneIcon color="inherit" />,
		value: annotation,
	},
	sr_object: {
		label: 'SR Object',
		icon: <SvgIcon color="inherit" component={KeyboardExternalInput} />,
		value: sr_object,
	},
	cad_findings: {
		label: 'CAD Findings',
		icon: <SettingsInputAntennaIcon color="inherit" />,
		value: cad_findings,
	},
};

export const OAI_MEASUREMENTS = [
	AngleTool?.toolName,
	BidirectionalTool?.toolName,
	EllipticalROITool?.toolName,
	LengthTool?.toolName,
	PlanarFreehandROITool?.toolName,
	ProbeTool?.toolName,
	RectangleROITool?.toolName,
	CobbAngleTool?.toolName,
	CardiothoracicTool?.toolName,
	PlumbLineTool?.toolName,
	SpineLabelingTool?.toolName,
];

export const ANNOTATION_TYPES = [ArrowAnnotateTool?.toolName];

export const SR_OBJECT = [sr_object];

export const CAD_FINDINGS = [cad_findings];

export const TOOL_TYPES = [
	{
		icon: (
			<SettingsInputAntennaIcon
				data-testid="measurement-type-tooltip-cad"
				fontSize="small"
				sx={{
					cursor: 'pointer',
				}}
			/>
		),
		label: 'CAD Findings',
		tools: CAD_FINDINGS,
	},
	{
		icon: (
			<TextRotationNoneIcon
				data-testid="measurement-type-tooltip-annotitation"
				sx={{
					cursor: 'pointer',
				}}
			/>
		),
		label: 'Annotation',
		tools: ANNOTATION_TYPES,
	},
	{
		icon: (
			<div>
				<SRobjectIcon data-testid="measurement-type-tooltip-sr" fontSize="small" />
			</div>
		),
		label: 'SR Object',
		tools: SR_OBJECT,
	},
];

export const FILTER_TYPES = {
	all: {
		toolName: null,
	},
	measurements: {
		toolName: OAI_MEASUREMENTS,
	},
	annotation: {
		toolName: ANNOTATION_TYPES,
	},
	sr_object: {
		toolName: SR_OBJECT,
	},
	cad_findings: {
		toolName: CAD_FINDINGS,
	},
};

export const LATERALITY_TYPES = {
	left: 'L',
	right: 'R',
	both: 'B',
	unpair: 'U',
};

export const BODY_PART_ONLY = [
	{
		id: 'brain_head',
		value: ['Brain', 'Head'],
	},
	{
		id: 'sinuses_sinus',
		value: ['Sinuses', 'Sinus'],
	},
	{
		id: 'temporomandibular_join',
		value: ['Temporomandibular joint'],
	},
	{
		id: 'neck',
		value: ['Neck'],
	},
	{
		id: 'lung_mediastinum',
		value: ['Lung', 'Mediastinum'],
	},
	{
		id: 'chest_thotacic',
		value: ['Chest', 'Thoracic'],
	},
	{
		id: 'liver_gallbladder',
		value: ['Liver', 'Gallbladder'],
	},
	{
		id: 'pancreas_spleen',
		value: ['Pancreas', 'Spleen'],
	},
	{
		id: 'abdoomen_lower_spine_lumbar',
		value: ['Abdomen', 'Lower Spine', 'Lumbar', 'Spine', 'Lumbar Spine'],
	},
	{
		id: 'pelvis_spine_sacral',
		value: ['Pelvis', 'Spine', 'Sacral'],
	},
];

export const BODY_PART_LATERALITY = [
	{
		side: 'L',
		locations: [
			{
				id: 'sholder_left',
				value: ['Shoulder'],
			},
			{
				id: 'upper_extrimity_left',
				value: ['Upper Extremity'],
			},
			{
				id: 'hand_left',
				value: ['Hand'],
			},
			{
				id: 'kidney_left',
				value: ['Kidney'],
			},
			{
				id: 'hip_left',
				value: ['Hip'],
			},
			{
				id: 'thight_left',
				value: ['Thigh'],
			},
			{
				id: 'knee_left',
				value: ['Knee'],
			},
			{
				id: 'ankle_foot_left',
				value: ['Ankle', 'Foot'],
			},
		],
	},
	{
		side: 'R',
		locations: [
			{
				id: 'sholder_right',
				value: ['Shoulder'],
			},
			{
				id: 'upper_extrimity_right',
				value: ['Upper Extremity'],
			},
			{
				id: 'hand_right',
				value: ['Hand'],
			},
			{
				id: 'kidney_right',
				value: ['Kidney'],
			},
			{
				id: 'hip_right',
				value: ['Hip'],
			},
			{
				id: 'thight_right',
				value: ['Thigh'],
			},
			{
				id: 'knee_right',
				value: ['Knee'],
			},
			{
				id: 'ankle_foot_right',
				value: ['Ankle', 'Foot'],
			},
		],
	},
];

export const FN_TYPES = {
	load: 'load',
	update: 'update',
	save: 'save',
};

export const MEASUREMENT_SEQUENCE = 'Measurement Sequence';
export const TIME_STAMP = 'Measurement Creation DateTime';
